import PaginationSearchBar from './PaginationSearchBar'
import PerPageSelect from './PerPageSelect'
import PaginationShowingText from './PaginationShowingText'
import Icon from '../common/Icon'
import { IconButton, OutlineButton } from '../common/Button'

type PaginationProps = {
	currentPage: number
	totalPages: number
	onPageChange: (page: number) => void
}

const Pagination = ({
	currentPage,
	totalPages,
	onPageChange,
}: PaginationProps) => {
	const getPages = () => {
		const pages = []
		if (totalPages <= 7) {
			for (let i = 1; i <= totalPages; i++) {
				pages.push(i)
			}
		} else {
			if (currentPage <= 4) {
				for (let i = 1; i <= 5; i++) {
					pages.push(i)
				}
				pages.push('...')
				pages.push(totalPages)
			} else if (currentPage >= totalPages - 3) {
				pages.push(1)
				pages.push('...')
				for (let i = totalPages - 4; i <= totalPages; i++) {
					pages.push(i)
				}
			} else {
				pages.push(1)
				pages.push('...')
				for (let i = currentPage - 1; i <= currentPage + 1; i++) {
					pages.push(i)
				}
				pages.push('...')
				pages.push(totalPages)
			}
		}
		return pages
	}

	return (
		<div className="flex items-center space-x-0.5 overflow-x-auto text-gray-500">
			<IconButton
				onClick={() => onPageChange(currentPage - 1)}
				disabled={currentPage === 1}
			>
				<Icon name="ChevronLeft" size={16} />
			</IconButton>
			{getPages().map((page, index) =>
				page === '...' ? (
					<p className="px-2" key={index}>
						...
					</p>
				) : (
					<OutlineButton
						key={index}
						className={`${page === currentPage ? 'bg-primary text-white hover:bg-primary' : ''}`}
						onClick={() => typeof page === 'number' && onPageChange(page)}
						disabled={page === '...' || page === currentPage}
					>
						{page}
					</OutlineButton>
				),
			)}
			<IconButton
				onClick={() => onPageChange(currentPage + 1)}
				disabled={currentPage === totalPages}
			>
				<Icon name="ChevronRight" size={16} />
			</IconButton>
		</div>
	)
}

export default Pagination

export { PaginationSearchBar, PerPageSelect, PaginationShowingText }
