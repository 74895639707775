import Breadcrumb from '@/components/Breadcrumb'
import ChangePassword from '@/components/Cards/ChangePassword'
import Notes from '@/components/Cards/NotesExample'
import TowFactorAuthentication from '@/components/Cards/TowFactorAuthentication'
import DefaultLayout from '@/layouts/DefaultLayout'

const MyProfileAuthentication = () => {
	return (
		<DefaultLayout>
			<Breadcrumb />

			<div className="py-2">
				<h2 className="mb-2 mt-0 text-xl font-semibold text-gray-800">
					Authentication
				</h2>
			</div>

			<div className="columns-1 space-y-4 sm:columns-1 lg:columns-2">
				<div className="break-inside-avoid-column space-y-4">
					<ChangePassword />
				</div>

				<div className="break-inside-avoid-column space-y-4">
					<TowFactorAuthentication />
				</div>

				{/* <div className="break-inside-avoid-column space-y-4">
					<Notes />
				</div> */}
			</div>
		</DefaultLayout>
	)
}

export default MyProfileAuthentication
