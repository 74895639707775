import Icon from '@/components/common/Icon'
import Breadcrumb from '@/components/Breadcrumb'
import UserStatus from '@/components/UserStatus'
import CopyToClipboardButton from '@/components/CopyToClipboardButton'
import DefaultLayout from '../layouts/DefaultLayout'
import Pagination, {
	PaginationSearchBar,
	PaginationShowingText,
	PerPageSelect,
} from '@/components/Pagination'
import { useState } from 'react'
import BadgeDot from '@/components/common/Badge/BadgeDot'
import { IconButton } from '@/components/common/Button'
import {
	Table,
	TableBody,
	TableHeaderRow,
	TableItem,
	TableRow,
} from '@/components/common/Table'
import { Link } from 'react-router-dom'

const data = {
	page: 1,
	per_page: 10,
	count: 13,
	total_pages: 2,
	items: [
		{
			id: '7605f536-706d-4e07-ac37-bc17e026337f',
			email: 'blob@gmail.com',
			status: 'active',
			created_at: '2024-04-23 19:33:05',
			updated_at: '2024-04-23 19:33:05',
		},
		{
			id: 'a0330a72-3886-47a2-915c-6d8ac597478f',
			email: 'alice@gmail.com',
			status: 'active',
			created_at: '2024-04-23 19:33:05',
			updated_at: '2024-04-23 19:33:05',
		},
		{
			id: '51425b3e-ba18-471a-8e3b-c408ca80b95f',
			email: 'JohnDoe@gmail.com',
			status: 'active',
			created_at: '2024-04-23 19:33:05',
			updated_at: '2024-04-24 08:21:45',
		},
		{
			id: 'd83925b7-6b6e-42e4-a8c9-591b3a724a97',
			email: 'mike@gmail.com',
			status: 'active',
			created_at: '2024-04-24 09:33:05',
			updated_at: '2024-04-24 09:33:05',
		},
		{
			id: 'b6a52c77-40db-4f9f-a4e1-0bfb230f4a73',
			email: 'susan@gmail.com',
			status: 'pending_verification',
			created_at: '2024-04-24 09:34:05',
			updated_at: '2024-04-24 09:34:05',
		},
		{
			id: '88b2c2e5-2b42-4c15-8d17-16d918b6c1d1',
			email: 'tom@gmail.com',
			status: 'suspended',
			created_at: '2024-04-24 09:35:05',
			updated_at: '2024-04-24 09:35:05',
		},
		{
			id: '2f4c850a-f0b8-42ea-9f20-49ff124b77a4',
			email: 'linda@gmail.com',
			status: 'active',
			created_at: '2024-04-24 09:36:05',
			updated_at: '2024-04-24 09:36:05',
		},
		{
			id: '9fa212a4-d88f-4c9e-bb56-c9b9ecf7c7af',
			email: 'steve@gmail.com',
			status: 'deleted',
			created_at: '2024-04-24 09:37:05',
			updated_at: '2024-04-24 09:37:05',
		},
		{
			id: 'ab3f9d2f-91f5-4b6e-9b9a-39447d74b36d',
			email: 'nina@gmail.com',
			status: 'deleted',
			created_at: '2024-04-24 09:38:05',
			updated_at: '2024-04-24 09:38:05',
		},
		{
			id: '0c7f9d7e-7d0e-411e-90de-48aebb88fddd',
			email: 'paul@gmail.com',
			status: 'pending_verification',
			created_at: '2024-04-24 09:39:05',
			updated_at: '2024-04-24 09:39:05',
		},
		{
			id: '6b9e4d63-84f8-486f-b65d-74cc5b53d172',
			email: 'emma@gmail.com',
			status: 'active',
			created_at: '2024-04-24 09:40:05',
			updated_at: '2024-04-24 09:40:05',
		},
		{
			id: 'f8e2c0d4-9ed1-4b5e-8dcb-399f0eb3f2cd',
			email: 'chris@gmail.com',
			status: 'suspended',
			created_at: '2024-04-24 09:41:05',
			updated_at: '2024-04-24 09:41:05',
		},
		{
			id: '35b31f70-2cf6-4e0a-80d7-cb4a2f7d831f',
			email: 'anna@gmail.com',
			status: 'active',
			created_at: '2024-04-24 09:42:05',
			updated_at: '2024-04-24 09:42:05',
		},
		{
			id: '84b70b68-7811-4c3e-a1a4-8cddb68749b3',
			email: 'charlie@gmail.com',
			status: 'active',
			created_at: '2024-04-22 08:21:15',
			updated_at: '2024-04-22 08:21:15',
		},
		{
			id: '91d2a3b5-e743-4d99-a29b-9a947a5e5e92',
			email: 'diana@gmail.com',
			status: 'active',
			created_at: '2024-04-21 12:32:45',
			updated_at: '2024-04-21 12:32:45',
		},
		{
			id: 'a4b6c7d8-e8a1-4b59-9b4f-6a3b5e8d92f1',
			email: 'edward@gmail.com',
			status: 'suspended',
			created_at: '2024-04-20 11:45:50',
			updated_at: '2024-04-20 11:45:50',
		},
		{
			id: 'b5c6d7e8-f9b2-4c60-a4b5-7b4c6d8f92e2',
			email: 'fiona@gmail.com',
			status: 'pending_verification',
			created_at: '2024-04-19 10:14:20',
			updated_at: '2024-04-19 10:14:20',
		},
		{
			id: 'c6d7e8f9-0a3b-4d61-b5c6-8d9f0a3e92d3',
			email: 'george@gmail.com',
			status: 'active',
			created_at: '2024-04-18 09:30:00',
			updated_at: '2024-04-18 09:30:00',
		},
		{
			id: 'd7e8f9a0-1b4c-4e72-c6d7-9e0b1c4f92d4',
			email: 'hannah@gmail.com',
			status: 'suspended',
			created_at: '2024-04-17 08:45:35',
			updated_at: '2024-04-17 08:45:35',
		},
		{
			id: 'e8f9a0b1-2c5d-4f83-d7e8-0f1c2d5e92d5',
			email: 'ian@gmail.com',
			status: 'active',
			created_at: '2024-04-16 07:50:25',
			updated_at: '2024-04-16 07:50:25',
		},
		{
			id: 'f9a0b1c2-3d6e-4f94-e8f9-1g2d3e5f92d6',
			email: 'jane@gmail.com',
			status: 'deleted',
			created_at: '2024-04-15 06:55:45',
			updated_at: '2024-04-15 06:55:45',
		},
	],
}

const Users = () => {
	const [currentPage, setCurrentPage] = useState<number>(1)
	const perPage = 100
	const totalPages = 10
	const totalEntries = 1000

	const handlePageChange = (page: number): void => {
		setCurrentPage(page)
	}

	return (
		<DefaultLayout>
			<Breadcrumb />

			{/* <!-- header content --> */}
			<div className="flex items-center justify-between text-sm md:pb-2">
				<div>
					<h1 className="font-medium text-gray-800">User accounts</h1>
					<p className="text-xs">View accounts of registered users</p>
				</div>
				<div className="flex gap-2"></div>
			</div>
			{/* <!-- header content end --> */}

			<hr className="-mx-4" />

			<div className="flex items-center justify-between text-sm md:pb-2">
				<div className="flex gap-2">
					<PerPageSelect />
					<PaginationSearchBar />
				</div>
				<div className="flex gap-2">
					<IconButton className="relative">
						<Icon name="FilterHorizontal" />
						<div className="absolute right-0 top-0 -translate-y-0 translate-x-0">
							<BadgeDot />
						</div>
					</IconButton>
				</div>
			</div>

			{/* <!-- users table --> */}
			<div className="w-full overflow-x-auto">
				<Table>
					{/* <!-- table header --> */}
					<TableHeaderRow>
						<TableItem>ID</TableItem>

						<TableItem>Email</TableItem>

						<TableItem>
							Created At
							<button>
								<Icon name="ArrowsUpDown" size={12} />
							</button>
						</TableItem>

						<TableItem>
							Updated At
							<button>
								<Icon name="ArrowsUpDown" size={12} />
							</button>
						</TableItem>

						<TableItem className="w-[240px]">Status</TableItem>
					</TableHeaderRow>
					{/* <!-- table header --> */}

					{/* <!-- table rows --> */}
					<TableBody>
						{data.items.length > 0 &&
							data.items.map((user) => (
								<TableRow key={user.id}>
									<TableItem>
										<p className="w-[70px] font-medium text-gray-800">
											{user.id.substring(0, 8)}
										</p>
										<CopyToClipboardButton value={user.id} />
									</TableItem>

									<TableItem>
										<p>{user.email}</p>
									</TableItem>

									<TableItem>
										<p>{user.created_at}</p>
									</TableItem>

									<TableItem>
										<p>{user.updated_at}</p>
									</TableItem>

									<TableItem className="w-[240px] justify-between">
										<UserStatus status={user.status} />
										<Link
											to={`/users/${user.id}`}
											className="rounded-md p-1 hover:bg-gray-200"
											title="view"
										>
											<Icon name="Eye" size={16} />
										</Link>
									</TableItem>
								</TableRow>
							))}
					</TableBody>
					{/* <!-- table rows end --> */}
				</Table>
			</div>
			{/* <!-- users table end --> */}

			{/* <!-- pagination --> */}
			<div className="space-y-4 py-4">
				<PaginationShowingText
					currentPage={currentPage}
					perPage={perPage}
					totalEntries={totalEntries}
				/>
				<Pagination
					currentPage={currentPage}
					totalPages={totalPages}
					onPageChange={handlePageChange}
				/>
			</div>
			{/* <!-- pagination end --> */}
		</DefaultLayout>
	)
}

export default Users
