import { createContext, useState, useEffect, ReactNode } from 'react'

import { IUser } from '../types/models/user'

interface Auth {
	user: IUser
	token: string
}

export interface AuthContextType {
	auth: Auth | null
	// user?: User | null
	// token?: string | null
	login: (userData: IUser, authToken: string) => void
	logout: () => void
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	// const [user, setUser] = useState<User | null>(null)
	// const [token, setToken] = useState<string | null>(null)
	const [auth, setAuth] = useState<Auth | null>(() => {
		const userStr = localStorage.getItem('user')
		const authToken = localStorage.getItem('token')
		if (userStr && authToken) {
			const userData = JSON.parse(userStr) as IUser
			return { user: userData, token: authToken }
		}
		return null
	})

	// useEffect(() => {
	// 	const userCache = localStorage.getItem('user')
	// 	if (userCache) {
	// 		const currentUser = JSON.parse(userCache) as User
	// 		setUser(currentUser)
	// 	}
	// }, [])

	// useEffect(() => {
	// 	setToken(localStorage.getItem('token'))
	// }, [])

	const login = (userData: IUser, authToken: string): void => {
		// setUser(userData)
		// setToken(authToken)
		setAuth({ user: userData, token: authToken })
	}

	const logout = (): void => {
		// setUser(null)
		// setToken(null)
		localStorage.removeItem('user')
		localStorage.removeItem('token')
		setAuth(null)
	}

	return (
		<AuthContext.Provider value={{ auth, login, logout }}>
			{children}
		</AuthContext.Provider>
	)
}
