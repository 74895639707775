import Icon from './common/Icon'

// enum EUserStatus {
// 	ACTIVE = 'active',
// 	PENDING_VERIFICATION = 'pending_verification',
// 	SUSPENDED = 'suspended',
// 	DELETED = 'deleted',
// }

type StatusType = {
	[name: string]: {
		icon: JSX.Element
		label: string
	}
}

const statusTypes: StatusType = {
	active: {
		icon: <Icon name="CheckCircleSolid" size={16} className="text-green-500" />,
		label: 'Active',
	},
	pending_verification: {
		icon: (
			<Icon name="WarningCircleSolid" size={16} className="text-blue-500" />
		),
		label: 'Pending Verification',
	},
	suspended: {
		icon: (
			<Icon name="MinusCircleSolid" size={16} className="text-orange-500" />
		),
		label: 'Suspended',
	},
	deleted: {
		icon: <Icon name="XCircleSolid" size={16} className="text-red-500" />,
		label: 'Deleted',
	},
}

type StatusName = keyof typeof statusTypes

const UserStatus = ({ status }: { status: StatusName }) => {
	const { icon, label } = statusTypes[status]

	return (
		<div className="flex items-center gap-1 rounded-full border p-1">
			{icon}
			<p className="text-xs">{label}</p>
		</div>
	)
}

export default UserStatus
