import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const Breadcrumb = () => {
	const location = useLocation()
	const pathnames = location.pathname.split('/').filter((x) => x)

	return (
		<nav className="w-full rounded-lg bg-gray-100 px-5 py-2 text-xs">
			<ol className="list-reset flex">
				<li>
					<Link to="/dashboard" className="text-blue-500 hover:text-blue-600">
						Home
					</Link>
				</li>
				{pathnames.map((value, index) => {
					const to = `/${pathnames.slice(0, index + 1).join('/')}`
					const isLast = index === pathnames.length - 1

					return (
						<React.Fragment key={to}>
							<li className="mx-2">/</li>
							{isLast ? (
								<li className="text-gray-500">{value}</li>
							) : (
								<li>
									<Link to={to} className="text-blue-500 hover:text-blue-600">
										{value}
									</Link>
								</li>
							)}
						</React.Fragment>
					)
				})}
			</ol>
		</nav>
	)
}

export default Breadcrumb
