import { FC } from 'react'

interface CheckboxProps {
	id: string
	label: string
	checked?: boolean
	disabled?: boolean
	defaultChecked?: boolean
}

const Checkbox: FC<CheckboxProps> = ({ id, label, ...rest }) => {
	return (
		<div className="flex">
			<input type="checkbox" id={id} className="w-3.5" {...rest} />
			<label htmlFor={id} className="ms-3 text-sm text-gray-500">
				{label}
			</label>
		</div>
	)
}

export default Checkbox
