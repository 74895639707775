import React, { ReactNode } from 'react'

const fixedStyles =
	'all-center rounded-lg duration-200 disabled:opacity-50 disabled:cursor-not-allowed '

const variantStyles = {
	primary: 'bg-primary text-white hover:bg-primary-active ',
	secondary: 'border hover:bg-gray-100 ',
	light: 'hover:bg-gray-100 ',
}
type VariantTypes = keyof typeof variantStyles

const sizeStyles = {
	// small: 'px-2.5 py-1.5 text-xs', // px-[10px] py-[6px]
	small: 'h-8 px-2 py-1 text-xs ',
	medium: 'px-4 py-2 text-sm ',
	large: 'px-6 py-3 text-md ',
}
type SizeTypes = keyof typeof sizeStyles

type ButtonProps = {
	disabled?: boolean
	variant?: VariantTypes
	size?: SizeTypes
	children?: ReactNode
	className?: string
	loading?: boolean
}

export default function Button({
	children,
	className,
	disabled = false,
	variant = 'primary',
	size = 'medium',
	loading = false,
}: ButtonProps) {
	return (
		<button
			className={`${fixedStyles} ${sizeStyles[size]} ${variantStyles[variant]} ${className}`}
			disabled={loading ? loading : disabled}
		>
			{loading && (
				<svg
					className="-ml-1 mr-1 h-4 w-4 shrink-0 animate-spin"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					fill="currentColor"
				>
					<path fill="none" d="M0 0h24v24H0z"></path>
					<path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"></path>
				</svg>
			)}
			{children}
		</button>
	)
}

interface IButtonProps extends React.ComponentPropsWithoutRef<'button'> {
	size?: SizeTypes
}

const PrimaryButton = React.forwardRef<HTMLButtonElement, IButtonProps>(
	({ size = 'small', className, ...props }, ref) => (
		<button
			ref={ref}
			className={`${sizeStyles[size]} all-center gap-1 rounded-lg bg-primary text-white duration-200 ${className}`}
			{...props}
		/>
	),
)
PrimaryButton.displayName = 'PrimaryButton'

const OutlineButton = React.forwardRef<HTMLButtonElement, IButtonProps>(
	({ size = 'small', className, ...props }, ref) => (
		<button
			ref={ref}
			className={`${sizeStyles[size]} all-center gap-1 rounded-lg border text-gray-500 duration-200 hover:bg-gray-100 ${className}`}
			{...props}
		/>
	),
)
OutlineButton.displayName = 'OutlineButton'

const IconButton = React.forwardRef<
	HTMLButtonElement,
	React.ComponentPropsWithoutRef<'button'>
>(({ className, ...props }, ref) => (
	<button
		ref={ref}
		className={`all-center h-8 w-8 rounded-lg text-gray-500 duration-200 hover:bg-gray-100 disabled:text-gray-300 ${className}`}
		{...props}
	/>
))
IconButton.displayName = 'IconButton'

export { PrimaryButton, OutlineButton, IconButton }
