import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Icon from '../components/common/Icon'
import { ensureError } from '../utils/error'
import { ILogin } from '../types/models/auth'
import { useAuth } from '../hooks/useAuth'
import { useToast } from '../hooks/useToast'
import InputField from '../components/InputField'
import Button from '../components/Button'
import Checkbox from '../components/Checkbox'
import Input from '../components/Input'
import Divider from '../components/Divider'
import LoginLayout from '../layouts/LoginLayout'

const emailMustValidErrMsg = 'Must be a valid email'
const emailIsRequiredErrMsg = 'Email is required'
const passwordIsRequiredErrMsg = 'Password is required'

async function postLogin(credentials: ILogin): Promise<Record<string, any>> {
	const response: Response = await fetch('http://localhost:8787/auth/login', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(credentials),
	})

	if (!response.ok) {
		const error = await response.json()
		throw new Error(error.message ?? response.statusText)
	}

	return response.json()
}

export default function LoginPage() {
	const navigate = useNavigate()
	const { auth, login } = useAuth()
	const toast = useToast()

	const [email, setEmail] = useState<string>('')
	const [haveEverOnBlurEmail, setHaveEverOnBlurEmail] = useState<boolean>(false)
	const [emailErrMsg, setEmailErrMsg] = useState<string | null>(null)

	const [password, setPassword] = useState<string>('')
	const [haveEverOnBlurPassword, setHaveEverOnBlurPassword] =
		useState<boolean>(false)
	const [passwordErrMsg, setPasswordErrMsg] = useState<string | null>(null)
	const [passwType, setPasswType] = useState<'password' | 'text'>('password')
	const [iconPasswToggle, setIconPasswToggle] = useState<JSX.Element>(
		<Icon name="EyeSlashSolid" size={20} className="text-gray-400" />,
	)

	useEffect(() => {
		if (auth) {
			localStorage.setItem('token', auth.token)
			localStorage.setItem('user', JSON.stringify(auth.user))
			navigate('/dashboard')
		}
	}, [auth])

	const isValidEmail = (email: string): boolean => {
		const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
		return isValid
	}

	const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value
		setEmail(value)
		if (haveEverOnBlurEmail) {
			if (!value) {
				setEmailErrMsg(emailIsRequiredErrMsg)
			} else if (!isValidEmail(value)) {
				setEmailErrMsg(emailMustValidErrMsg)
			} else {
				setEmailErrMsg(null)
			}
		}
	}

	const handleBlurEmail = (e: ChangeEvent<HTMLInputElement>) => {
		setHaveEverOnBlurEmail(true)
		const value = e.target.value
		if (!value) {
			setEmailErrMsg(emailIsRequiredErrMsg)
		} else if (!isValidEmail(value)) {
			setEmailErrMsg(emailMustValidErrMsg)
		}
	}

	const handlePassword = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value
		setPassword(value)
		if (haveEverOnBlurPassword) {
			if (!value) {
				setPasswordErrMsg(passwordIsRequiredErrMsg)
			} else {
				setPasswordErrMsg(null)
			}
		}
	}

	const handleBlurPassword = (e: ChangeEvent<HTMLInputElement>) => {
		setHaveEverOnBlurPassword(true)
		const value = e.target.value
		if (!value) {
			setPasswordErrMsg(passwordIsRequiredErrMsg)
		}
	}

	const handleTogglePassword = () => {
		if (passwType === 'password') {
			setIconPasswToggle(
				<Icon name="EyeSolid" size={20} className="text-gray-400" />,
			)
			setPasswType('text')
		} else {
			setIconPasswToggle(
				<Icon name="EyeSlashSolid" size={20} className="text-gray-400" />,
			)
			setPasswType('password')
		}
	}

	const handleSubmit = async (e: SyntheticEvent): Promise<void> => {
		e.preventDefault()
		let passed = true

		setHaveEverOnBlurEmail(true)
		if (!email) {
			setEmailErrMsg(emailIsRequiredErrMsg)
			passed = false
		} else if (email && !isValidEmail(email)) {
			setEmailErrMsg(emailMustValidErrMsg)
			passed = false
		}

		setHaveEverOnBlurPassword(true)
		if (!password) {
			setPasswordErrMsg(passwordIsRequiredErrMsg)
			passed = false
		}

		if (passed) {
			try {
				const loginResponse = await postLogin({ email, password })
				login(loginResponse.user, loginResponse.access_token)
				toast.success('Login successfully')
			} catch (error) {
				const err = ensureError(error)
				toast.error(err.message)
			}
		}
	}

	return (
		<>
			<LoginLayout>
				<div className="text-center">
					<h1 className="my-3 text-3xl font-semibold text-gray-700">Login</h1>
					<p className="max-w mt-2 text-center text-sm leading-5 text-gray-600">
						Don't have an account?{' '}
						<a
							href="#"
							className="font-sm text-blue-500 transition duration-150 ease-in-out hover:text-blue-400 focus:underline focus:outline-none"
						>
							Create an account
						</a>
					</p>
				</div>

				<div className="m-7">
					<form onSubmit={handleSubmit}>
						<InputField
							onChange={handleEmail}
							onBlur={handleBlurEmail}
							value={email}
							name="email"
							labelText="Email"
							placeholder="you@example.com"
							error={emailErrMsg}
						/>

						<div className="mt-6">
							<label
								htmlFor="password"
								className="mb-1 block text-sm font-bold text-gray-700"
							>
								Password
							</label>
							<div className="relative">
								<Input
									name="password"
									placeholder="Password"
									type={passwType}
									onChange={handlePassword}
									onBlur={handleBlurPassword}
									value={password}
									error={passwordErrMsg}
								/>
								<button
									onClick={handleTogglePassword}
									type="button"
									className="absolute end-0 top-0 rounded-e-md p-2"
								>
									{iconPasswToggle}
								</button>
							</div>
							{passwordErrMsg && (
								<p className="mt-2 text-sm text-red-600">{passwordErrMsg}</p>
							)}
						</div>

						<div className="mt-2 flex items-center justify-between">
							<Checkbox id="remember" label="Remember me" />
							<div className="text-sm leading-5">
								<a
									href="#"
									className="font-sm text-blue-500 transition duration-150 ease-in-out hover:text-blue-400 focus:underline focus:outline-none"
								>
									Forgot your password?
								</a>
							</div>
						</div>

						<div className="mt-8">
							<Button text="Login" customClass="w-full justify-center" />
						</div>
					</form>
					<Divider>OR</Divider>
					<div className="flex flex-col gap-2">
						<button className=" inline-flex w-full items-center justify-center gap-2 rounded-lg border border-slate-300 bg-white py-1.5 text-sm font-medium text-black outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-60">
							{/* <img
								src="https://www.svgrepo.com/show/475656/google-color.svg"
								alt="Google"
								className="h-[18px] w-[18px] "
							/> */}
							<Icon name="Google" size={18} />
							Continue with Google
						</button>
					</div>
				</div>
			</LoginLayout>
		</>
	)
}
