import DropdownUser from './DropdownUser'
import Icon from '../common/Icon'
import DarkModeSwitcher from './DarkModeSwitcher'
import DropdownNotification from './DropdownNotification'

const Navbar = (props: {
	isSidebarOpen: boolean | undefined
	setIsSidebarOpen: (arg0: boolean) => void
}) => {
	return (
		<>
			<header className="sticky top-0 z-10 bg-white">
				<div className="flex items-center justify-between p-4 text-gray-500 md:p-4">
					{/* <!-- Content left --> */}
					<div className="flex h-10 items-center justify-between gap-2">
						{/* <!-- Show icon hamburger on mobile --> */}
						<button
							onClick={() => props.setIsSidebarOpen(true)}
							className="all-center h-8 w-8 text-gray-500 md:hidden"
						>
							<Icon name="Bar3Solid" />
						</button>
						{/*  <!-- Show icon hamburger on mobile End --> */}

						{/* <!-- Icon on mobile --> */}
						<div className="group flex cursor-pointer items-center gap-2 md:hidden">
							<div className="flex h-10 w-10 items-center justify-center rounded-full bg-gradient-to-br from-violet-500 to-violet-400 text-white outline outline-violet-300">
								<Icon
									name="RectangleGroup"
									size={24}
									className="relative duration-200 group-hover:scale-75"
								/>
							</div>
						</div>
						{/* <!-- Icon on mobile End --> */}
					</div>
					{/* <!-- Content left End --> */}

					{/* <!-- Content right --> */}
					<div className="flex items-center justify-between gap-2 text-gray-500">
						<DropdownNotification />
						<DarkModeSwitcher />
						<DropdownUser />
					</div>
					{/* <!-- Content right End --> */}
				</div>

				<hr className="mx-2 bg-gray-400" />
			</header>
		</>
	)
}

export default Navbar
