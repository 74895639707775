import { ChangeEvent, FC } from 'react'

const fixedInputClass = `
	px-4
	py-1.5
	block
	w-full
	rounded-lg
	text-sm

	bg-gray-100
	border-transparent
	border
  border-gray-100

	focus:border-blue-500
	focus:ring-blue-500
	focus:outline-none
	
	disabled:opacity-50
	disabled:pointer-events-none

`
// dark:bg-neutral-900
// dark:border-neutral-700
// dark:text-neutral-400
// dark:placeholder-neutral-500
// dark:focus:ring-neutral-600

interface InputProps {
	name: string
	value: string | number
	type?: 'text' | 'number' | 'email' | 'password'
	placeholder?: string
	required?: boolean
	disabled?: boolean
	customClass?: string
	error?: string | null
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void
	onBlur?: (e: ChangeEvent<HTMLInputElement>) => void
}

const Input: FC<InputProps> = ({
	name,
	error = undefined,
	customClass = '',
	...rest
}) => {
	return (
		<input
			id={name}
			name={name}
			className={
				fixedInputClass +
				(customClass && ' ' + customClass + ' ') +
				(error && ' border-red-500')
			}
			{...rest}
		/>
	)
}

export default Input
