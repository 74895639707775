import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Breadcrumb from '@/components/Breadcrumb'
import { IUser } from '@/types'
import DefaultLayout from '@/layouts/DefaultLayout'
import { HttpService } from '@/services'
import { HttpException, ensureHttpException } from '@/utils'

const UserView = () => {
	const http = new HttpService()

	const { id } = useParams()

	const [user, setUser] = useState<IUser | null>(null)
	const [isLoading, setIsLoading] = useState(true)
	const [error, setError] = useState<HttpException | null>(null)

	useEffect(() => {
		const fetchUser = async () => {
			try {
				const data = await http.service().get<IUser>(`/users/${id}`)
				setUser(data)
				// log
				console.log(data)
			} catch (error) {
				const err = ensureHttpException(error)
				setError(err)
				// log
				console.log({ err })
			} finally {
				setIsLoading(false)
			}
		}

		fetchUser()
	}, [id])

	if (isLoading) {
		return (
			<DefaultLayout>
				<div className="container mx-auto p-4">Loading...</div>
			</DefaultLayout>
		)
	}

	if (error) {
		return (
			<DefaultLayout>
				<div className="container mx-auto p-4">
					Error: {error.status} {error.message}
				</div>
			</DefaultLayout>
		)
	}

	return (
		<DefaultLayout>
			<Breadcrumb />

			<div>Id = {user?.id}</div>
			<div>Email = {user?.email}</div>
			<div>Status =</div>
			<div>Create at = {user?.created_at?.toString()}</div>
			<div>Updated at = {user?.updated_at?.toString()}</div>
		</DefaultLayout>
	)
}

export default UserView
