// import AdjustmentsHorizontalSolid from '../../../assets/svg/adjustments-horizontal-solid.svg?react'
// import ArrowDownTraySolid from '../../../assets/svg/arrow-down-tray-solid.svg?react'
// import ArrowPathSolid from '../../../assets/svg/arrow-path-solid.svg?react'
import ArrowRight2 from '../../../assets/svg/arrow-right-2.svg?react'
import ArrowsUpDown from '../../../assets/svg/arrows-up-down.svg?react'
// import ArrowUpTraySolid from '../../../assets/svg/arrow-up-tray-solid.svg?react'
import Bar3Solid from '../../../assets/svg/bars-3-solid.svg?react'
// import BellSolid from '../../../assets/svg/bell-solid.svg?react'
import Bell from '../../../assets/svg/bell.svg?react'
import Blog from '../../../assets/svg/blog.svg?react'
import CheckCircleSolid from '../../../assets/svg/check-circle-solid.svg?react'
import Check from '../../../assets/svg/check.svg?react'
import ChevronDown from '../../../assets/svg/chevron-down.svg?react'
import ChevronLeft from '../../../assets/svg/chevron-left.svg?react'
import ChevronRight from '../../../assets/svg/chevron-right.svg?react'
import ChevronUpDown from '../../../assets/svg/chevron-up-down.svg?react'
import ChevronUp from '../../../assets/svg/chevron-up.svg?react'
import Copy from '../../../assets/svg/copy.svg?react'
// import EllipsisVerticalSolid from '../../../assets/svg/ellipsis-vertical-solid.svg?react'
import EyeSlashSolid from '../../../assets/svg/eye-slash-solid.svg?react'
import EyeSlash from '../../../assets/svg/eye-slash.svg?react'
import EyeSolid from '../../../assets/svg/eye-solid.svg?react'
import Eye from '../../../assets/svg/eye.svg?react'
import FilterHorizontal from '../../../assets/svg/filter-horizontal.svg?react'
import Google from '../../../assets/svg/google-icon.svg?react'
// import HomeSolid from '../../../assets/svg/home-solid.svg?react'
import Home from '../../../assets/svg/home.svg?react'
import InfoCircleSolid from '../../../assets/svg/info-circle-solid.svg?react'
import Logout from '../../../assets/svg/logout.svg?react'
// import MagnifyingGlassSolid from '../../../assets/svg/magnifying-glass-solid.svg?react'
import MinusCircleSolid from '../../../assets/svg/minus-circle-solid.svg?react'
// import MoonSolid from '../../../assets/svg/moon-solid.svg?react'
import Moon from '../../../assets/svg/moon.svg?react'
import More from '../../../assets/svg/more.svg?react'
// import PencilSquareSolid from '../../../assets/svg/pencil-square-solid.svg?react'
import RectangleGroup from '../../../assets/svg/rectangle-group.svg?react'
import Search from '../../../assets/svg/search.svg?react'
// import SettingSolid from '../../../assets/svg/setting-solid.svg?react'
import Setting from '../../../assets/svg/setting.svg?react'
// import SunSolid from '../../../assets/svg/sun-solid.svg?react'
import Sun from '../../../assets/svg/sun.svg?react'
// import TrashSolid from '../../../assets/svg/trash-solid.svg?react'
// import UserCircleSolid from '../../../assets/svg/user-circle-solid.svg?react'
// import UserPlusSolid from '../../../assets/svg/user-plus-solid.svg?react'
// import UserSolid from '../../../assets/svg/user-solid.svg?react'
// import UsersSolid from '../../../assets/svg/users-solid.svg?react'
import Users from '../../../assets/svg/users.svg?react'
import User from '../../../assets/svg/user.svg?react'
import WarningCircleSolid from '../../../assets/svg/warning-circle-solid.svg?react'
import XCircleSolid from '../../../assets/svg/x-circle-solid.svg?react'
import X from '../../../assets/svg/x.svg?react'

const icons = {
	// AdjustmentsHorizontalSolid,
	// ArrowDownTraySolid,
	// ArrowPathSolid,
	ArrowRight2,
	ArrowsUpDown,
	// ArrowUpTraySolid,
	Bar3Solid,
	// BellSolid,
	Bell,
	Blog,
	CheckCircleSolid,
	Check,
	ChevronDown,
	ChevronLeft,
	ChevronRight,
	ChevronUpDown,
	ChevronUp,
	Copy,
	// EllipsisVerticalSolid,
	EyeSlashSolid,
	EyeSlash,
	EyeSolid,
	Eye,
	FilterHorizontal,
	Google,
	// HomeSolid,
	Home,
	InfoCircleSolid,
	Logout,
	// MagnifyingGlassSolid,
	MinusCircleSolid,
	// MoonSolid,
	Moon,
	More,
	// PencilSquareSolid,
	RectangleGroup,
	Search,
	// SettingSolid,
	Setting,
	// SunSolid,
	Sun,
	// TrashSolid,
	// UserCircleSolid,
	// UserPlusSolid,
	// UserSolid,
	// UsersSolid,
	Users,
	User,
	WarningCircleSolid,
	XCircleSolid,
	X,
}

export type IconName = keyof typeof icons

type IconComponentProps = {
	name: IconName
	size?: string | number
	color?: string
	className?: string
}

const FIXED_CLASS_NAME = ''

const Icon = ({
	name,
	size = 24,
	color = 'currentColor',
	className = '',
}: IconComponentProps) => {
	let Icon = icons[name]
	if (!Icon) return null

	return (
		<Icon
			height={size}
			width={size}
			color={color}
			className={FIXED_CLASS_NAME + className}
		/>
	)
}

export default Icon
