import Breadcrumb from '@/components/Breadcrumb'
import DefaultLayout from '@/layouts/DefaultLayout'

const MyProfileNotifications = () => {
	return (
		<DefaultLayout>
			<Breadcrumb />

			<div>Notifications</div>
		</DefaultLayout>
	)
}

export default MyProfileNotifications
