import Breadcrumb from '@/components/Breadcrumb'
import DefaultLayout from '@/layouts/DefaultLayout'

const Blog = () => {
	return (
		<DefaultLayout>
			<Breadcrumb />

			<div>This is Blog</div>
		</DefaultLayout>
	)
}

export default Blog
