import { useRef, useState } from 'react'
import Icon from '../common/Icon'
import { Link } from 'react-router-dom'

const DropdownNotification = () => {
	const [notifying, setNotifying] = useState(true)

	const trigger = useRef<any>(null)
	const dropdown = useRef<any>(null)

	return (
		<div className="relative">
			<Link
				ref={trigger}
				onClick={() => {}}
				to="#"
				className="all-center h-8 w-8 rounded-lg duration-200 hover:bg-gray-100"
			>
				<span
					className={`z-1 absolute right-1 top-1 h-2 w-2 rounded-full bg-red-500 ${
						notifying === false ? 'hidden' : 'inline'
					}`}
				>
					<span className="-z-1 absolute inline-flex h-full w-full animate-ping rounded-full bg-red-500 opacity-75"></span>
				</span>
				<Icon name="Bell" size={16} />
			</Link>
		</div>
	)
}

export default DropdownNotification
