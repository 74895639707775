type BadgeDotProps = {
	className?: string
	animatePing?: boolean
}

const BadgeDot = ({ className, animatePing }: BadgeDotProps) => {
	return (
		<span className={`relative flex size-2 ${className}`}>
			{animatePing && (
				<span className="absolute inline-flex size-full animate-ping rounded-full bg-red-400 opacity-75"></span>
			)}
			<span className="relative inline-flex size-2 rounded-full bg-red-500"></span>
		</span>
	)
}

export default BadgeDot
