import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Icon from '../common/Icon'
import ProfileImage from '../../assets/profile.png'

const DropdownUser = () => {
	const [dropdownOpen, setDropdownOpen] = useState(false)

	const trigger = useRef<any>(null)
	const dropdown = useRef<any>(null)

	// close on click outside
	useEffect(() => {
		const clickHandler = ({ target }: MouseEvent) => {
			if (!dropdown.current) return
			if (
				!dropdownOpen ||
				dropdown.current.contains(target) ||
				trigger.current.contains(target)
			) {
				return
			}
			setDropdownOpen(false)
		}
		document.addEventListener('click', clickHandler)
		return () => document.removeEventListener('click', clickHandler)
	})

	// close if the esc key is pressed
	useEffect(() => {
		const keyHandler = (event: KeyboardEvent) => {
			if (!dropdownOpen || event.key !== 'Escape') return
			setDropdownOpen(false)
		}
		document.addEventListener('keydown', keyHandler)
		return () => document.removeEventListener('keydown', keyHandler)
	})

	return (
		<div className="relative">
			<Link
				ref={trigger}
				onClick={() => setDropdownOpen(!dropdownOpen)}
				className="flex items-center gap-4"
				to="#"
			>
				<div className="hidden text-right md:block">
					<p className="text-sm font-semibold text-gray-800">
						steve_job@mail.com
					</p>
					<p className="text-xs font-medium text-gray-500">
						steve_job@mail.com
					</p>
				</div>
				{/* <img
					src={ProfileImage}
					alt="User"
					width={40}
					height={40}
					className="rounded-full"
				/> */}
				<div className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-indigo-500 text-sm text-white">
					ST
				</div>
			</Link>

			{/* <!-- Dropdown --> */}
			<div
				ref={dropdown}
				onFocus={() => setDropdownOpen(true)}
				onBlur={() => setDropdownOpen(false)}
				className={`border-stroke shadow-default dark:border-strokedark dark:bg-boxdark absolute right-0 mt-4 flex w-48 flex-col rounded-lg border bg-white ${
					dropdownOpen === true ? 'block' : 'hidden'
				}`}
			>
				<ul className="border-stroke dark:border-strokedark flex flex-col gap-4 border-b px-6 py-4">
					<li>
						<Link
							to="/profile"
							className="flex items-center gap-3.5 text-xs font-medium duration-300 ease-in-out hover:text-primary"
						>
							<Icon name="User" size={16} />
							My Profile
						</Link>
					</li>
					<li>
						<Link
							to="/settings"
							className="flex items-center gap-3.5 text-xs font-medium duration-300 ease-in-out hover:text-primary"
						>
							<Icon name="Setting" size={16} />
							Settings
						</Link>
					</li>
				</ul>
				<button className="flex items-center gap-3.5 px-6 py-4 text-xs font-medium duration-300 ease-in-out hover:text-primary">
					<Icon name="Logout" size={16} />
					Log Out
				</button>
			</div>
			{/* <!-- Dropdown End --> */}
		</div>
	)
}

export default DropdownUser
