import Icon from '../common/Icon'

const PaginationSearchBar = () => {
	return (
		<div className="relative mx-auto text-gray-600">
			<input
				className="h-8 rounded-lg border border-gray-300 bg-white px-4 pr-10 text-sm focus:border-gray-400 focus:outline-none"
				type="search"
				name="search"
				placeholder="Search"
			/>
			<button type="submit" className="absolute right-0 top-0 mr-4 mt-2">
				<Icon name="Search" size={16} />
			</button>
		</div>
	)
}

export default PaginationSearchBar
