import DefaultLayout from '../layouts/DefaultLayout'

export default function DashboardPage() {
	return (
		<DefaultLayout>
			<p>
				Welcome to your Dashboard Welcome to your Dashboard Welcome to your
				Dashboard Welcome to your Dashboard Welcome to your Dashboard Welcome to
				your Dashboard
			</p>
			<br />
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
			<p>Welcome to your Dashboard</p>
		</DefaultLayout>
	)
}
