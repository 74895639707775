import { useEffect, useRef, useState } from 'react'

import Icon from '../common/Icon'
import { useToast } from '../../hooks/useToast'
import { ToastPayload } from '../../reducers/ToastReducer'

type ToastType = {
	[name: string]: {
		icon: JSX.Element
		iconClass: 'success-icon' | 'warning-icon' | 'info-icon' | 'error-icon'
		progressBarClass: 'success' | 'warning' | 'info' | 'error'
	}
}

const toastTypes: ToastType = {
	success: {
		icon: <Icon name="CheckCircleSolid" />,
		iconClass: 'success-icon',
		progressBarClass: 'success',
	},
	warning: {
		icon: <Icon name="WarningCircleSolid" />,
		iconClass: 'warning-icon',
		progressBarClass: 'warning',
	},
	info: {
		icon: <Icon name="InfoCircleSolid" />,
		iconClass: 'info-icon',
		progressBarClass: 'info',
	},
	error: {
		icon: <Icon name="XCircleSolid" />,
		iconClass: 'error-icon',
		progressBarClass: 'error',
	},
}

export interface ToastProps extends ToastPayload {}

const Toast = ({ id, message, type }: ToastProps) => {
	const { icon, iconClass, progressBarClass } = toastTypes[type]
	const toast = useToast()
	const timerID = useRef<NodeJS.Timeout | null>(null)
	const progressRef = useRef<HTMLDivElement | null>(null)
	const [dismissed, setDismissed] = useState<boolean>(false)

	const handleDismiss = () => {
		setDismissed(true)
		setTimeout(() => {
			toast.remove(id)
		}, 400)
	}

	useEffect(() => {
		timerID.current = setTimeout(() => {
			handleDismiss()
		}, 4000)

		return () => {
			if (timerID.current) clearTimeout(timerID.current)
		}
	}, [])

	const handleMouseEnter = () => {
		if (timerID.current) clearTimeout(timerID.current)
		if (progressRef.current)
			progressRef.current.style.animationPlayState = 'paused'
	}

	const handleMouseLeave = () => {
		if (
			progressRef.current &&
			progressRef.current.offsetWidth &&
			progressRef.current.parentElement
		) {
			const remainingTime =
				(progressRef.current.offsetWidth /
					progressRef.current.parentElement.offsetWidth) *
				4000

			progressRef.current.style.animationPlayState = 'running'

			timerID.current = setTimeout(() => {
				handleDismiss()
			}, remainingTime)
		}
	}

	return (
		<div
			className={`toast ${dismissed ? 'toast-dismissed' : ''}`}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<span className={iconClass}>{icon}</span>
			<p className="toast-message">{message}</p>
			<button className="dismiss-btn" onClick={handleDismiss}>
				<Icon name="X" size={18} color="#aeb0d7" />
			</button>

			<div className="toast-progress">
				<div
					ref={progressRef}
					className={`toast-progress-bar ${progressBarClass}`}
				></div>
			</div>
		</div>
	)
}

export default Toast
