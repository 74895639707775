const PaginationShowingText = ({
	currentPage,
	perPage,
	totalEntries,
}: {
	currentPage: number
	perPage: number
	totalEntries: number
}) => {
	const calculateShowingEntries = () => {
		const startEntry = (currentPage - 1) * perPage + 1
		const endEntry = Math.min(currentPage * perPage, totalEntries)

		const x = startEntry.toLocaleString(undefined, { maximumFractionDigits: 2 })
		const y = endEntry.toLocaleString(undefined, { maximumFractionDigits: 2 })
		const z = totalEntries.toLocaleString(undefined, {
			maximumFractionDigits: 2,
		})
		return { x, y, z }
	}
	const { x, y, z } = calculateShowingEntries()

	return (
		<p className="text-sm text-gray-500">
			Showing {x} to {y} of {z} entries
		</p>
	)
}

export default PaginationShowingText
