import { ReactNode, createContext, useReducer } from 'react'

import { ToastState, toastReducer } from '../reducers/ToastReducer'
import ToastsContainer from '../components/Toast/ToastContainer'

export interface ToastContextType {
	success: (message: string) => void
	warning: (message: string) => void
	info: (message: string) => void
	error: (message: string) => void
	remove: (id: number) => void
}

export const ToastContext = createContext<ToastContextType>(
	{} as ToastContextType,
)

const initialState: ToastState = {
	toasts: [],
}

export const ToastContextProvider = ({ children }: { children: ReactNode }) => {
	const [state, dispatch] = useReducer(toastReducer, initialState)

	const addToast = (type: string, message: string) => {
		const id = Math.floor(Math.random() * 10000000)
		dispatch({ type: 'ADD_TOAST', payload: { id, message, type } })
	}

	const remove = (id: number) => {
		dispatch({
			type: 'DELETE_TOAST',
			payload: {
				id,
				message: '',
				type: '',
			},
		})
	}

	const success = (message: string) => {
		addToast('success', message)
	}

	const warning = (message: string) => {
		addToast('warning', message)
	}

	const info = (message: string) => {
		addToast('info', message)
	}

	const error = (message: string) => {
		addToast('error', message)
	}

	const value = { success, warning, info, error, remove }

	return (
		<ToastContext.Provider value={value}>
			<ToastsContainer toasts={state.toasts} />
			{children}
		</ToastContext.Provider>
	)
}
