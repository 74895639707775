import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios'
import { IService, EHttpMethod } from '@/types'
import { HttpException } from '@/utils'
// import Cookies from "js-cookie";

class HttpService {
	private http: AxiosInstance
	private baseURL = import.meta.env.VITE_API_BASE_URL

	constructor() {
		this.http = axios.create({
			baseURL: this.baseURL,
			withCredentials: false,
			headers: this.setupHeaders(),
		})
	}

	// Get authorization token for requests
	private get getAuthorization() {
		const accessToken = localStorage.getItem('token') || ''
		return accessToken ? { Authorization: `Bearer ${accessToken}` } : {}
	}

	// Initialize service configuration
	public service() {
		this.injectInterceptors()

		return this
	}

	// Set up request headers
	private setupHeaders(hasAttachment = false) {
		return hasAttachment
			? { 'Content-Type': 'multipart/form-data', ...this.getAuthorization }
			: { 'Content-Type': 'application/json', ...this.getAuthorization }
	}

	// Handle HTTP requests
	private async request<T>(
		method: EHttpMethod,
		url: string,
		options: AxiosRequestConfig,
	): Promise<T> {
		try {
			const response: AxiosResponse<T> = await this.http.request<T>({
				method,
				url,
				...options,
			})

			return response.data
		} catch (error) {
			throw this.normalizeError(error)
		}
	}

	// Perform GET request
	public async get<T>(
		url: string,
		params?: IService.IParams,
		hasAttachment = false,
	): Promise<T> {
		return this.request<T>(EHttpMethod.GET, url, {
			params,
			headers: this.setupHeaders(hasAttachment),
		})
	}

	// Perform POST request
	public async push<T, P>(
		url: string,
		payload: P,
		params?: IService.IParams,
		hasAttachment = false,
	): Promise<T> {
		return this.request<T>(EHttpMethod.POST, url, {
			params,
			data: payload,
			headers: this.setupHeaders(hasAttachment),
		})
	}

	// Perform UPDATE request
	public async update<T, P>(
		url: string,
		payload: P,
		params?: IService.IParams,
		hasAttachment = false,
	): Promise<T> {
		return this.request<T>(EHttpMethod.PUT, url, {
			params,
			data: payload,
			headers: this.setupHeaders(hasAttachment),
		})
	}

	// Perform DELETE request
	public async remove<T>(
		url: string,
		params?: IService.IParams,
		hasAttachment = false,
	): Promise<T> {
		return this.request<T>(EHttpMethod.DELETE, url, {
			params,
			headers: this.setupHeaders(hasAttachment),
		})
	}

	// Inject interceptors for request and response
	private injectInterceptors() {
		// Set up request interceptor
		this.http.interceptors.request.use((request) => {
			// * Perform an action
			// TODO: implement an NProgress
			return request
		})

		// Set up response interceptor
		this.http.interceptors.response.use(
			(response) => {
				// * Do something
				return response
			},

			(error) => {
				// * Implement a global error alert
				return Promise.reject(error)
			},
		)
	}

	// Normalize errors
	// private normalizeError(error: any) {
	// 	return Promise.reject(error)
	// }
	private normalizeError(error: any): HttpException {
		const status = error.response?.status || 500
		const message =
			error.response?.data?.message ||
			error.message ||
			'An unexpected error occurred'

		// return Promise.reject(new HttpException(status, message))
		return new HttpException(status, message)
	}
}

export { HttpService as default }
