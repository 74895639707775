import Toast, { ToastProps } from './Toast'

type ToastContainerProps = {
	toasts: ToastProps[]
	position?:
		| 'top-right'
		| 'top-left'
		| 'top-center'
		| 'bottom-left'
		| 'bottom-center'
		| 'bottom-right'
}

const ToastsContainer = ({
	toasts,
	position = 'top-right',
}: ToastContainerProps) => {
	return (
		<div className={`toasts-container ${position}`}>
			{toasts.map((toast) => (
				<Toast key={toast.id} {...toast} />
			))}
		</div>
	)
}

export default ToastsContainer
