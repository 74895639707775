import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import './index.css'
import './App.css'
import './components/Toast/Toast.css'
import NotFoundPage from './pages/404'
import LoginPage from './pages/Login'
import DashboardPage from './pages/Dashboard'
import ProtectedRoute from './auth/ProtectedRoute'
import Users from './pages/Users'
import Settings from './pages/Settings'
import UserView from './pages/UserView'
import MyProfilePreferences from './pages/MyProfile/Preferences'
import MyProfileAuthentication from './pages/MyProfile/Authentication'
import Blog from './pages/Blog'
import MyProfileNotifications from './pages/MyProfile/Notifications'

export default function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<Routes>
					<Route index element={<Navigate to="/login" />} />
					<Route path="/login" element={<LoginPage />} />
					<Route element={<ProtectedRoute />}>
						<Route path="/dashboard" element={<DashboardPage />} />
						<Route path="/profile" element={<MyProfilePreferences />} />
						<Route
							path="/profile/authentication"
							element={<MyProfileAuthentication />}
						/>
						<Route
							path="/profile/notifications"
							element={<MyProfileNotifications />}
						/>
						<Route path="/users" element={<Users />} />
						<Route path="/users/:id" element={<UserView />} />
						<Route path="/blog" element={<Blog />} />
						<Route path="/settings" element={<Settings />} />
					</Route>
					<Route path="*" element={<NotFoundPage />} />
				</Routes>
			</BrowserRouter>
		</div>
	)
}
