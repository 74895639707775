import React from 'react'

const Table = React.forwardRef<
	HTMLTableElement,
	React.ComponentPropsWithoutRef<'table'>
>(({ className, ...props }, ref) => (
	<table
		ref={ref}
		className={`w-full overflow-x-auto rounded-md text-sm ${className}`}
		{...props}
	/>
))
Table.displayName = 'Table'

const TableHeaderRow = React.forwardRef<
	HTMLTableRowElement,
	React.ComponentPropsWithoutRef<'tr'>
>(({ className, ...props }, ref) => (
	// <tr>
	// 	<div
	// 		ref={ref}
	// 		className={`flex items-center justify-between rounded-lg bg-gray-100 text-gray-500 ${className}`}
	// 		{...props}
	// 	/>
	// </tr>
	<thead>
		<tr
			ref={ref}
			className={`flex items-center justify-between rounded-lg bg-gray-100 text-gray-500 ${className}`}
			{...props}
		/>
	</thead>
))
TableHeaderRow.displayName = 'TableHeaderRow'

const TableBody = React.forwardRef<
	HTMLTableSectionElement,
	React.ComponentPropsWithoutRef<'tbody'>
>(({ className, ...props }, ref) => (
	<tbody ref={ref} className={`space-y-2 divide-y ${className}`} {...props} />
))
TableBody.displayName = 'TableBody'

const TableRow = React.forwardRef<
	HTMLTableRowElement,
	React.ComponentPropsWithoutRef<'tr'>
>(({ className, ...props }, ref) => (
	<tr
		ref={ref}
		className={`flex items-center justify-between text-gray-700 duration-150 hover:bg-gray-100 ${className}`}
		{...props}
	/>
))
TableRow.displayName = 'TableRow'

const TableItem = React.forwardRef<
	HTMLTableCellElement,
	React.ComponentPropsWithoutRef<'td'>
>(({ className, ...props }, ref) => (
	<td
		ref={ref}
		className={`flex w-[220px] items-center gap-2 px-2 py-1.5 text-sm ${className}`}
		{...props}
	/>
))
TableItem.displayName = 'TableItem'

export { Table, TableHeaderRow, TableBody, TableItem, TableRow }
