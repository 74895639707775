import { FC, ChangeEvent } from 'react'
import Input from './Input'

interface InputFieldProps {
	onChange: (e: ChangeEvent<HTMLInputElement>) => void
	onBlur?: (e: ChangeEvent<HTMLInputElement>) => void
	labelText: string
	name: string
	value: string | number
	type?: 'text' | 'number' | 'email' | 'password'
	placeholder?: string
	required?: boolean
	disabled?: boolean
	customClass?: string
	error?: string | null
}

const InputField: FC<InputFieldProps> = ({
	onChange,
	onBlur,
	labelText,
	name,
	value,
	customClass = '',
	error = undefined,
	...rest
}) => {
	return (
		// <div className="max-w-sm">
		<div>
			<label
				htmlFor={name}
				className="mb-1 block text-sm font-bold text-gray-700"
			>
				{labelText}
			</label>
			<Input
				onChange={onChange}
				onBlur={onBlur}
				value={value}
				name={name}
				customClass={customClass}
				{...rest}
			/>
			{error && <p className="mt-2 text-sm text-red-600">{error}</p>}
		</div>
	)
}

export default InputField
