import React, { ReactNode, useState } from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import PageContent from './PageContent'

const DefaultLayout: React.FC<{ children: ReactNode }> = ({
	children,
}: {
	children: ReactNode
}) => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false)

	return (
		<div className="bg-white dark:bg-white">
			<div
				className={`${isSidebarOpen ? 'overflow-hidden' : ''} h-screen overflow-y-auto`}
			>
				{/* <!-- Backdrop - หลังเบลอเมื่อเปิด sidebar บน mobile --> */}
				{isSidebarOpen && (
					<div
						onClick={() => setIsSidebarOpen(false)}
						className="absolute left-0 top-0 z-20 h-screen w-full bg-black/60 md:hidden"
					/>
				)}
				{/* <!-- Backdrop End --> */}

				{/* <!-- Mobile sidebar --> */}
				{isSidebarOpen && (
					<div className="absolute left-0 top-0 z-30 md:hidden">
						<Sidebar
							isSidebarOpen={isSidebarOpen}
							setIsSidebarOpen={setIsSidebarOpen}
						/>
					</div>
				)}
				{/* <!-- Mobile sidebar End --> */}

				<div className="grid w-screen md:grid-cols-[240px_1fr]">
					{/* <div className='grid md:grid-cols-[240px_1fr] w-screen overflow-x-hidden'> */}

					{/* <!-- Sidebar - sidebar บนหน้าจอปกติ --> */}
					<div className="hidden md:block">
						<Sidebar
							isSidebarOpen={isSidebarOpen}
							setIsSidebarOpen={setIsSidebarOpen}
						/>
					</div>
					{/* <!-- Sidebar End --> */}

					{/* <div className="mx-auto w-full max-w-[1440px]"> */}
					<div className="mx-auto w-full max-w-[1440px] overflow-x-auto">
						{/* <!-- Header --> */}
						<Navbar
							isSidebarOpen={isSidebarOpen}
							setIsSidebarOpen={setIsSidebarOpen}
						/>
						{/* <!-- Header End --> */}

						{/* <!-- Main Content --> */}
						<PageContent>{children}</PageContent>
						{/* <!-- Main Content End --> */}
					</div>
				</div>
			</div>
		</div>
	)
}

export default DefaultLayout
