import { FC } from 'react'

const fixedButtonClass = `
  px-4
  py-1.5
  inline-flex
  items-center
  gap-x-2
  text-sm
  font-semibold
  rounded-lg
  border
  border-transparent
  bg-blue-600
  text-white
  hover:bg-blue-700
  disabled:opacity-50
  disabled:pointer-events-none
`

interface ButtonProps {
	onClick?: () => void
	text: string
	customClass?: string
}

const Button: FC<ButtonProps> = ({ onClick, text, customClass }) => {
	return (
		<button onClick={onClick} className={fixedButtonClass + customClass}>
			{text}
		</button>
	)
}

export default Button
