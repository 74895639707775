import Breadcrumb from '@/components/Breadcrumb'
import DefaultLayout from '@/layouts/DefaultLayout'

const MyProfilePreferences = () => {
	return (
		<DefaultLayout>
			<Breadcrumb />

			<div>Preferences</div>
		</DefaultLayout>
	)
}

export default MyProfilePreferences
