import { ChangeEvent, useState } from 'react'
import Icon from '../common/Icon'

type PerPageSelectProps = {
	onChange?: (value: number) => {}
	options?: number[]
}

const PerPageSelect = ({
	onChange,
	options = [10, 20, 50, 100],
}: PerPageSelectProps) => {
	const [selectedOption, setSelectedOption] = useState(options[0])

	const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
		const value = parseInt(event.target.value, 10)
		setSelectedOption(value)
		if (onChange) {
			onChange(value)
		}
	}

	return (
		<div className="relative inline-flex">
			<Icon
				name="ChevronUpDown"
				className="pointer-events-none absolute right-0 top-0 m-2"
				size={16}
			/>
			<select
				value={selectedOption}
				onChange={handleChange}
				className="h-8 appearance-none rounded-lg border border-gray-300 bg-white pl-2 pr-8 text-gray-600 hover:border-gray-400 focus:outline-none"
			>
				{options.map((option) => (
					<option key={option} value={option}>
						{option}
					</option>
				))}
			</select>
		</div>
	)
}

export default PerPageSelect
