import { Navigate, Outlet } from 'react-router-dom'

import { useAuth } from '../hooks/useAuth'

const ProtectedRoute = ({ children }: any) => {
	const { auth } = useAuth()
	if (!auth) {
		return <Navigate replace to="/login" />
	}

	return children ? children : <Outlet />
}

export default ProtectedRoute
