import Badge from '../common/Badge'

const TowFactorAuthentication = () => {
	return (
		<div className="w-full rounded-2xl border p-6 text-gray-500">
			{/* header */}
			<div className="flex items-center justify-between">
				<div className="text-md flex items-center gap-2">
					<p className="font-semibold text-gray-800">
						Two-Factor Authentication (2FA)
					</p>
					<Badge text="Disabled" color="red" />
				</div>
			</div>
			{/* header end */}

			<hr className="my-4 bg-gray-400" />

			{/* content */}
			<div className="space-y-3">
				{/* two-factor end */}
				<div className="flex items-start gap-3">
					<p className="text-sm text-gray-700">
						Two-Factor Authentication (2FA) adds an extra layer of security to
						your account by requiring not only your password but also a second
						form of verification. This can be a code sent to your mobile device,
						a code generated by an authentication app, or a hardware token.
						Enabling 2FA significantly reduces the risk of unauthorized access
						to your account.
						<a href="#" className="ml-1 text-sm text-blue-500 hover:underline">
							Learn more
						</a>
					</p>
				</div>
				{/* two-factor end */}
			</div>
			{/* content end */}
		</div>
	)
}

export default TowFactorAuthentication
