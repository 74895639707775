import React from 'react'
import Icon, { IconName } from '../Icon'

const colorStyles: { [name: string]: any } = {
	primary: {
		filled: {
			spanStyle: 'bg-violet-100 text-violet-800',
			iconStyle: '',
		},
		outline: {
			spanStyle: 'border text-gray-800',
			iconStyle: 'text-violet-500',
		},
	},
	red: {
		filled: {
			spanStyle: 'bg-red-100 text-red-800',
			iconStyle: '',
		},
		outline: {
			spanStyle: 'border text-gray-800',
			iconStyle: 'text-red-500',
		},
	},
	green: {
		filled: {
			spanStyle: 'bg-green-100 text-green-800',
			iconStyle: '',
		},
		outline: {
			spanStyle: 'border text-gray-800',
			iconStyle: 'text-green-500',
		},
	},
	blue: {
		filled: {
			spanStyle: 'bg-blue-100 text-blue-800',
			iconStyle: '',
		},
		outline: {
			spanStyle: 'border text-gray-800',
			iconStyle: 'text-blue-500',
		},
	},
	orange: {
		filled: {
			spanStyle: 'bg-orange-100 text-orange-800',
			iconStyle: '',
		},
		outline: {
			spanStyle: 'border text-gray-800',
			iconStyle: 'text-orange-500',
		},
	},
}

const sizeStyles: { [name: string]: any } = {
	xxs: { spanSize: 'px-2 py-0.5 text-xxs', iconSize: 14 },
	xs: { spanSize: 'px-2 py-0.5 text-xs', iconSize: 16 },
	sm: { spanSize: 'px-2.5 py-0.5 text-sm', iconSize: 18 },
	md: { spanSize: 'px-3 py-0.5 text-md', iconSize: 20 },
	lg: { spanSize: 'px-3.5 py-0.5 text-lg', iconSize: 24 },
	xl: { spanSize: 'px-4 py-1 text-xl', iconSize: 24 },
}

type ColorTypes = keyof typeof colorStyles
type SizeTypes = keyof typeof sizeStyles

interface BadgeProps {
	text: string
	color?: ColorTypes
	variant?: 'filled' | 'outline'
	size?: SizeTypes
	icon?: IconName
}

const Badge = ({
	text,
	color = 'primary',
	variant = 'filled',
	size = 'sm',
	icon = undefined,
}: BadgeProps): React.ReactElement => {
	const { spanSize, iconSize } = sizeStyles[size]
	const { spanStyle, iconStyle } = colorStyles[color][variant]

	return (
		<span
			className={`inline-flex items-center justify-center rounded-full font-medium ${spanSize} ${spanStyle}`}
		>
			{icon && (
				<span className={`-ml-1 mr-1.5 ${iconStyle}`}>
					<Icon name={icon} size={iconSize} />
				</span>
			)}
			<span className="whitespace-nowrap">{text}</span>
		</span>
	)
}

export default Badge
