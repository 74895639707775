import { NavLink, useLocation } from 'react-router-dom'
import Icon from '../common/Icon'
import ProfileImage from '../../assets/profile.png'
import SidebarLinkGroup from './SidebarLinkGroup'

interface SidebarProps {
	isSidebarOpen: boolean
	setIsSidebarOpen: (arg: boolean) => void
}

const Sidebar = ({ isSidebarOpen, setIsSidebarOpen }: SidebarProps) => {
	const location = useLocation()
	const { pathname } = location

	return (
		<div className="sticky top-0 h-screen w-60 shrink-0 overflow-hidden md:block">
			<div className="h-full w-full border-r bg-white">
				{/* logo */}
				<div className="flex items-center justify-between">
					<div className="group flex cursor-pointer items-center gap-2 p-4 md:p-4">
						<div className="flex h-10 w-10 items-center justify-center rounded-full bg-gradient-to-br from-violet-500 to-violet-400 text-white outline outline-violet-300">
							<Icon
								name="RectangleGroup"
								size={24}
								className="relative duration-200 group-hover:scale-75"
							/>
						</div>
						<div>
							<h1 className="text-sm font-bold text-gray-800">Cat Paw Tech</h1>
							<p className="text-xs font-medium text-gray-500">
								Admin dashboard
							</p>
						</div>
					</div>
					<button
						onClick={() => setIsSidebarOpen(false)}
						className="px-4 text-gray-500 md:hidden"
					>
						<Icon name="X" size={16} />
					</button>
				</div>
				{/* end logo */}

				{/* section divider */}
				<hr className="mx-2 bg-gray-400" />
				{/* end section divider */}

				{/* other section */}
				<div className="flex h-full flex-col justify-between">
					{/* top */}
					<div className="space-y-2 overflow-y-auto pt-6 text-xs font-medium text-gray-500 md:px-2">
						<NavLink
							to="/dashboard"
							className={`flex ${pathname === '/dashboard' ? 'text-primary' : ''} items-center gap-2 px-6 py-2 duration-200 hover:px-8`}
						>
							<Icon name="Home" size={16} />
							Dashboard
						</NavLink>

						<div className="px-6 py-2 text-gray-400">PERSONAL</div>

						<ul className="mb-6 flex flex-col gap-2">
							<SidebarLinkGroup
								activeCondition={
									pathname === '/profile' || pathname.includes('profile')
								}
							>
								{(handleClick, open) => {
									return (
										<>
											<NavLink
												to="#"
												className={`group relative flex items-center gap-2 rounded-sm px-6 py-2 font-medium duration-300 ease-in-out ${
													(pathname === '/profile' ||
														pathname.includes('profile')) &&
													'text-primary'
												}`}
												onClick={(e) => {
													e.preventDefault()
													handleClick()
												}}
											>
												<Icon name="User" size={16} />
												My Profile
												<Icon
													name="ChevronDown"
													size={16}
													className={`absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 ${
														open && 'rotate-180'
													}`}
												/>
											</NavLink>
											{/* <!-- Dropdown Menu Start --> */}
											<div
												className={`translate transform overflow-hidden ${
													!open && 'hidden'
												}`}
											>
												<ul className="mb-4 mt-2 flex flex-col gap-2.5 pl-8">
													<li>
														<NavLink
															to="/profile"
															end
															className={({ isActive }) =>
																'group relative flex items-center gap-2.5 rounded-md px-4 font-medium duration-200 ease-in-out hover:px-6 ' +
																(isActive && '!text-primary')
															}
														>
															Preferences
														</NavLink>
													</li>
													<li>
														<NavLink
															to="/profile/authentication"
															className={({ isActive }) =>
																'group relative flex items-center gap-2.5 rounded-md px-4 font-medium duration-200 ease-in-out hover:px-6 ' +
																(isActive && '!text-primary')
															}
														>
															Authentication
														</NavLink>
													</li>
													<li>
														<NavLink
															to="/profile/notifications"
															className={({ isActive }) =>
																'group relative flex items-center gap-2.5 rounded-md px-4 font-medium duration-200 ease-in-out hover:px-6 ' +
																(isActive && '!text-primary')
															}
														>
															Notifications
														</NavLink>
													</li>
												</ul>
											</div>
											{/* <!-- Dropdown Menu End --> */}
										</>
									)
								}}
							</SidebarLinkGroup>
						</ul>

						<div className="px-6 py-2 text-gray-400">SYSTEM</div>

						<NavLink
							to="/users"
							className={`flex ${pathname === '/users' ? 'text-primary' : ''} items-center gap-2 px-6 py-2 duration-200 hover:px-8`}
						>
							<Icon name="Users" size={16} />
							Users
						</NavLink>

						<ul className="mb-6 flex flex-col gap-2">
							<SidebarLinkGroup
								activeCondition={
									pathname === '/blog' || pathname.includes('blog')
								}
							>
								{(handleClick, open) => {
									return (
										<>
											<NavLink
												to="#"
												className={`group relative flex items-center gap-2 rounded-sm px-6 py-2 font-medium duration-300 ease-in-out ${
													(pathname === '/blog' || pathname.includes('blog')) &&
													'text-primary'
												}`}
												onClick={(e) => {
													e.preventDefault()
													handleClick()
												}}
											>
												<Icon name="Blog" size={16} />
												Blog
												<Icon
													name="ChevronDown"
													size={16}
													className={`absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 ${
														open && 'rotate-180'
													}`}
												/>
											</NavLink>
											{/* <!-- Dropdown Menu Start --> */}
											<div
												className={`translate transform overflow-hidden ${
													!open && 'hidden'
												}`}
											>
												<ul className="mb-4 mt-2 flex flex-col gap-2.5 pl-8">
													<li>
														<NavLink
															to="/blog"
															end
															className={({ isActive }) =>
																'group relative flex items-center gap-2.5 rounded-md px-4 font-medium duration-200 ease-in-out hover:px-6 ' +
																(isActive && '!text-primary')
															}
														>
															List posts
														</NavLink>
													</li>
												</ul>
											</div>
											{/* <!-- Dropdown Menu End --> */}
										</>
									)
								}}
							</SidebarLinkGroup>
						</ul>

						<NavLink
							to="/settings"
							className={`flex ${pathname === '/settings' ? 'text-primary' : ''} items-center gap-2 px-6 py-2 duration-200 hover:px-8`}
						>
							<Icon name="Setting" size={16} />
							Settings
						</NavLink>
					</div>
					{/* end top */}

					<div>
						<div className="text-xs font-medium text-gray-500 md:px-2">
							<NavLink
								to="/settings"
								className={`flex ${pathname === '/settings' ? 'text-primary' : ''} items-center gap-2 px-6 py-2 duration-200 hover:px-8`}
							>
								<Icon name="Setting" size={16} />
								Settings
							</NavLink>
						</div>

						<hr className="mx-2 my-4 bg-gray-400" />

						{/* bottom */}
						<div className="flex cursor-pointer items-center justify-between px-4 pb-24 duration-200 hover:pr-5 md:px-6">
							<div className="flex items-center gap-2">
								{/* <img
									src={ProfileImage}
									alt="User"
									width={36}
									height={36}
									className="rounded-full"
								/> */}
								<div className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-indigo-500 text-sm text-white">
									ST
								</div>
								<div className="w-28 overflow-hidden truncate">
									<p className="text-sm font-semibold text-gray-800">
										steve_job@mail.com
									</p>
									<p className="text-xs font-medium text-gray-500">
										steve_job@mail.com
									</p>
								</div>
							</div>

							<button className="text-gray-500">
								<Icon name="Logout" size={16} />
							</button>
						</div>
						{/* end bottom */}
					</div>
				</div>
				{/* end other section */}
			</div>
		</div>
	)
}

export default Sidebar
