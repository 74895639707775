import { ChangeEvent, FC, useRef, useState } from 'react'
import Icon from '../Icon'

const fixedInputClass =
	'h-8 rounded-lg border border-gray-300 bg-white px-4 pr-10 text-sm focus:border-gray-400 focus:outline-none '

interface InputProps {
	name: string
	label?: string
	value: string | number
	type?: 'text' | 'number' | 'email' | 'password'
	placeholder?: string
	required?: boolean
	disabled?: boolean
	className?: string
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void
	onBlur?: (e: ChangeEvent<HTMLInputElement>) => void
	error?: string | null
	eye?: boolean
	showRequired?: boolean
}

const Input: FC<InputProps> = ({
	name,
	label = '',
	type: inputType = 'text',
	error = null,
	className = '',
	eye = false,
	showRequired = false,
	...rest
}) => {
	const [type, setCurrentType] = useState(inputType)
	const inputRef = useRef<HTMLInputElement>(null)

	const handleToggleEye = () => {
		setCurrentType(type === 'password' ? 'text' : 'password')
		if (inputRef.current) {
			inputRef.current.focus()
		}
	}

	return (
		<div className="mb-4">
			{label && (
				<label htmlFor={name} className="mb-2 block text-sm text-gray-700">
					<div className="flex items-center justify-between">
						<span>
							{label} {showRequired && <span className="text-red-500">*</span>}
						</span>
						{eye && (
							<button
								type="button"
								onClick={handleToggleEye}
								className="flex items-center gap-1 text-xs text-blue-500"
							>
								{type === 'password' ? (
									<>
										<Icon name="Eye" size={16} /> <div>Show</div>
									</>
								) : (
									<>
										<Icon name="EyeSlash" size={16} /> <div>Hide</div>
									</>
								)}
							</button>
						)}
					</div>
				</label>
			)}
			<input
				ref={inputRef}
				id={name}
				name={name}
				type={type}
				// className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${className} ${error ? 'border-red-500' : ''}`}
				className={`w-full appearance-none rounded-lg border border-gray-300 bg-white px-4 py-2 text-sm leading-tight focus:border-primary focus:outline-none ${className} ${error ? 'border-red-500' : ''}`}
				{...rest}
			/>
			{error && <p className="text-xs italic text-red-500">{error}</p>}
		</div>
	)
}

export default Input
