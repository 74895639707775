import Breadcrumb from '../components/Breadcrumb'
import DefaultLayout from '../layouts/DefaultLayout'

const Settings = () => {
	return (
		<DefaultLayout>
			<Breadcrumb />

			<div>Settings</div>
		</DefaultLayout>
	)
}

export default Settings
