export const ensureError = (value: unknown): Error => {
	if (value instanceof Error) {
		if (value instanceof Error) {
			const message = value.message
			if (message && !['{}', '[]', '""'].includes(message)) {
				return value
			}
		}
	}

	const error = new Error('An unexpected error occurred')
	return error
}

export class HttpException extends Error {
	public readonly status: number

	constructor(status: number, message: string) {
		super(message)

		this.status = status
		this.message = message
	}
}

export const ensureHttpException = (value: unknown): HttpException => {
	let status = 500
	let message = 'An unexpected error occurred'

	if (value instanceof HttpException) {
		return value
	} else if (value instanceof Error) {
		message = value.message
	}

	const error = new HttpException(status, message)
	return error
}
