export type ToastPayload = {
	id: number
	message: string
	type: string
}

export type ToastState = {
	toasts: ToastPayload[]
}

export type ToastAction = {
	type: string
	payload: ToastPayload
}

export const toastReducer = (state: ToastState, action: ToastAction) => {
	switch (action.type) {
		case 'ADD_TOAST':
			return {
				...state,
				toasts: [...state.toasts, action.payload],
			}
		case 'DELETE_TOAST':
			const updatedToasts = state.toasts.filter(
				(toast) => toast.id !== action.payload.id,
			)
			return {
				...state,
				toasts: updatedToasts,
			}
		default:
			throw new Error(`Unhandled action type: ${action.type}`)
	}
}
