import { useState } from 'react'
import Icon from './common/Icon'

const CopyToClipboardButton = ({ value }: { value: string }) => {
	const [copied, setCopied] = useState(false)

	const handleCopy = () => {
		navigator.clipboard.writeText(value).then(() => {
			setCopied(true)
			setTimeout(() => setCopied(false), 1000)
		})
	}

	return (
		<>
			<button disabled={copied} onClick={handleCopy} className="text-gray-500">
				{copied ? (
					<p className="text-xs">Copied</p>
				) : (
					<Icon name="Copy" size={16} />
				)}
			</button>
		</>
	)
}

export default CopyToClipboardButton
