import { ChangeEvent, SyntheticEvent, useState } from 'react'
import Input from '../common/Input'
import Button from '../common/Button'
import Icon from '../common/Icon'

interface FormErrors {
	password?: string
	newPassword?: string
	confirmNewPassword?: string
}

const ChangePassword = () => {
	const [isLoading, setIsLoading] = useState(false)

	const [password, setPassword] = useState<string>('')
	const [newPassword, setNewPassword] = useState<string>('')
	const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')
	const [errors, setErrors] = useState<FormErrors>({})

	const handlePassword = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value
		setPassword(value)
		if (errors?.password) {
			setErrors({
				...errors,
				password: undefined,
			})
		}
	}

	const handleNewPassword = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value
		setNewPassword(value)
		if (errors?.newPassword) {
			setErrors({
				...errors,
				newPassword: undefined,
			})
		}
	}

	const checks: { regex: RegExp; label: string }[] = [
		{ regex: /.{8,}/, label: '8 characters' },
		{ regex: /[a-zA-Z]/, label: '1 letter' },
		{ regex: /\d/, label: '1 number' },
		{
			regex: /[!@#$%^&*(),.?":{}|<>]/,
			label: '1 special character (e.g., !?@#$%<>)',
		},
		{
			regex: /^[^\s].*[^\s]$|^[^\s]$/,
			label: 'No leading or trailing whitespace',
		},
	]
	const checkNewPassword = (regex: RegExp) => regex.test(newPassword)

	const handleConfirmNewPassword = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value
		setConfirmNewPassword(value)
		if (errors?.confirmNewPassword) {
			setErrors({
				...errors,
				confirmNewPassword: undefined,
			})
		}
	}

	const handleSubmit = (e: SyntheticEvent) => {
		e.preventDefault()
		const validationErrors: FormErrors = {}

		if (!password) {
			validationErrors.password = 'Old password is required'
		}

		if (!newPassword) {
			validationErrors.newPassword = 'New password is required'
		} else {
			for (const check of checks) {
				if (!check.regex.test(newPassword)) {
					validationErrors.newPassword = `Password must be at least ${check.label.toLowerCase()}`
					break
				}
			}
		}

		if (!confirmNewPassword) {
			validationErrors.confirmNewPassword = 'Confirm new password is required'
		} else if (newPassword !== confirmNewPassword) {
			validationErrors.confirmNewPassword = 'Passwords do not match'
		}

		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors)
		} else {
			setErrors({})
			setIsLoading(true)

			setTimeout(() => {
				setIsLoading(false)
			}, 500)
		}
	}

	return (
		<div className="w-full rounded-2xl border p-6 text-gray-500">
			{/* header */}
			<div className="flex items-center justify-between">
				<div className="text-md flex items-center gap-2">
					<p className="font-semibold text-gray-800">Change Password</p>
				</div>
			</div>
			{/* header end */}

			<hr className="my-4 bg-gray-400" />

			{/* content */}
			<div className="space-y-3">
				{/* change password form */}
				<div className="flex items-start gap-3">
					<form className="w-full" onSubmit={handleSubmit}>
						<Input
							name="password"
							label="Old password"
							value={password}
							type="password"
							onChange={handlePassword}
							eye
							showRequired
							error={errors?.password}
						/>

						<Input
							name="new password"
							label="New password"
							value={newPassword}
							type="password"
							onChange={handleNewPassword}
							eye
							showRequired
							error={errors?.newPassword}
						/>

						<div className="mb-8 text-sm">
							Create a password that contains at least:
							<ul className="mt-2 list-disc space-y-1 pl-8 text-sm font-medium">
								{checks.map((check, index) => (
									<li
										key={index}
										className={`flex items-center ${
											checkNewPassword(check.regex) && 'text-green-600'
										}`}
									>
										{checkNewPassword(check.regex) && (
											<Icon name="Check" size={16} className="mr-1" />
										)}
										{check.label}
									</li>
								))}
							</ul>
						</div>

						<Input
							name="confirm new password"
							label="Confirm new password"
							value={confirmNewPassword}
							type="password"
							onChange={handleConfirmNewPassword}
							eye
							showRequired
							error={errors?.confirmNewPassword}
						/>

						<div className="mt-8 flex justify-end">
							<Button loading={isLoading}>
								{isLoading ? 'Saving...' : 'Save'}
							</Button>
						</div>
					</form>
				</div>
				{/* change password form end */}
			</div>
			{/* content end */}
		</div>
	)
}

export default ChangePassword
