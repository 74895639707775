import { FC, ReactNode } from 'react'

type DividerProps = {
	children: ReactNode
	fontSize?: number | string
}

const Divider: FC<DividerProps> = ({ children }) => {
	return (
		<div className="flex w-full items-center gap-2 py-6 text-sm text-gray-400">
			<div className="h-px w-full bg-slate-200"></div>
			{children}
			<div className="h-px w-full bg-slate-200"></div>
		</div>
	)
}

export default Divider
