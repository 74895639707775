/// <reference types="vite-plugin-svgr/client" />

import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './App'
import { AuthProvider } from './contexts/AuthContext'
import { ToastContextProvider } from './contexts/ToastContext'

const root = createRoot(document.getElementById('root')!)
root.render(
	<React.StrictMode>
		<ToastContextProvider>
			<AuthProvider>
				<App />
			</AuthProvider>
		</ToastContextProvider>
	</React.StrictMode>,
)
