import { ReactNode } from 'react'

const LoginLayout = ({ children }: { children: ReactNode }) => {
	return (
		<div className="flex min-h-screen items-center bg-white">
			<div className="container mx-auto">
				<div className="mx-auto my-10 max-w-md">{children}</div>
			</div>
		</div>
	)
}

export default LoginLayout
